import Image from "next/image";
import HomeGIF from "public/landing-signup-steps/home.gif";
import CashGIF from "public/landing-signup-steps/cash.gif";
import ComputerGIF from "public/landing-signup-steps/computer.gif";
import ButtonV2 from "elements/ButtonV2";

export const SignUpStepsSection = () => {
	const signUpStepsInfo = [
		{
			Icon: <Image src={HomeGIF} alt="Enter your address" />,
			title: "Enter your address.",
			description:
				"With just your address Ownwell will analyze your property expenses to find you savings using real time market data, local expertise and AI.",
		},
		{
			Icon: <Image src={ComputerGIF} alt="Answer some questions online" />,
			title: "Answer some questions.",
			description:
				"The more we know about your property, the more we can save you. There's no up-front costs, it's risk-free to sign up. Only pay if you save!",
		},
		{
			Icon: <Image src={CashGIF} alt="Unlock savings" />,
			title: "Unlock your savings.",
			description:
				"We gather evidence, file all documentation, and attend hearings on your behalf year after year, so you never overpay again.",
		},
	];

	return (
		<GeneralizedSignUpStepsSection
			titleNormal="Sign up in less than "
			titleHighlighted="3 minutes."
			signUpStepsInfo={signUpStepsInfo}
		/>
	);
};

export const GeneralizedSignUpStepsSection = ({
	titleNormal,
	titleHighlighted,
	signUpStepsInfo,
	isExemptionsFlow = false,
}: {
	titleNormal: string;
	titleHighlighted: string;
	signUpStepsInfo: { title: string; description: string; Icon: JSX.Element }[];
	isExemptionsFlow?: boolean;
}) => {
	return (
		<div id="generalized-signup-steps-section">
			<p className="landing-section-title mb-3">
				{titleNormal}
				<span className="azure-gradient">{titleHighlighted}</span>
			</p>
			<div id="landing-signup-steps-steps">
				{signUpStepsInfo.map(({ Icon, title, description }, index) => (
					<div className="landing-signup-step" key={title + "-" + index}>
						<div className="img-circle">{Icon}</div>
						<header>{title}</header>
						<p>{description}</p>
					</div>
				))}
			</div>
			<ButtonV2
				className="mx-auto landing-signup-today"
				size="large"
				toAppealFlow={{
					referrer: "Sign Up Steps section Sign Up Today",
					exemptions: isExemptionsFlow ? true : false,
				}}>
				Sign Up Today
			</ButtonV2>
		</div>
	);
};
