import statePages from "config/state-pages.json";
import { useMemo } from "react";
import { StatesWithStatePage } from "utils/types";
import Link from "next/link";
import Grid from "elements/Grid";
import {
	stateSpecificAppealWord,
	stateSpecificExemptionWord,
} from "utils/account";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

export const renderImage = (
	target?: StatesWithStatePage,
	imageUrl?: string
) => {
	if (target && imageUrl) {
		return <img src={imageUrl} alt={`State of ${statePages[target].name}`} />; // eslint-disable-line
	}
};

const PropertyTax101Section = ({ target }: { target: StatesWithStatePage }) => {
	const stateData = useMemo(
		() => (target && target !== "IL" ? statePages[target] : null),
		[target]
	);

	return (
		<div id="landing-property-tax-101-section">
			<Grid>
				<div className="landing-property-tax-101-section-img">
					{renderImage(target, stateData?.imageUrl)}
				</div>
				<div className="landing-property-tax-101-section-content">
					<h3 className="landing-property-tax-101-section-content-title h3-update">
						{stateData?.name} Property Tax&nbsp;101
					</h3>
					<p className="denim-medium">
						Find out how properties are appraised and taxed,{" "}
						{stateSpecificExemptionWord(target, { plural: true })}, the{" "}
						{stateSpecificAppealWord(target)} process and&nbsp;more.
					</p>
					<div className="landing-property-tax-101-section-content-cta">
						<Link
							passHref
							href={`/resources/${statePages[
								target
							].name.toLowerCase()}-property-taxes-101`}>
							<ButtonV2
								size="large"
								variant="secondary"
								className="landing-property-tax-101-section-content-btn">
								Learn More <RightArrow />
							</ButtonV2>
						</Link>
					</div>
				</div>
			</Grid>
		</div>
	);
};

export default PropertyTax101Section;
