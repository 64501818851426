import Button from "elements/Button";
import Router from "next/router";
import {
	useGoogleReviewRows,
	GoogleReviewsColumns,
	Star,
	StarPartial,
} from "../Testimonials/testimonials";
import { StatesWithStatePage } from "utils/types";
import ButtonV2 from "elements/ButtonV2";
import constants from "utils/constants";

export const TestimonialsSection = ({
	target,
}: {
	target?: StatesWithStatePage;
}) => {
	const { currentReviews, googleReviewRows } = useGoogleReviewRows(3, target);
	return (
		<div id="landing-testimonials-section">
			<p className="landing-section-title mb-2">
				<span className="azure-gradient">Tax savings</span> everyone is talking
				about.
			</p>
			<div id="testimonial-stats" className="mb-3">
				<p className="rating">4.8</p>
				<div className="stars">
					{Star}
					{Star}
					{Star}
					{Star}
					{StarPartial}
				</div>
				<p className="review-count">
					({constants.GOOGLE_REVIEW_COUNT}+ reviews)
				</p>
			</div>

			<div id="testimonials-page-endless-section">
				<GoogleReviewsColumns
					currentReviews={currentReviews}
					{...googleReviewRows}
				/>
			</div>
			<ButtonV2
				variant="primary-outline-filled"
				size="large"
				onClick={() => Router.push("/testimonials")}>
				Read More Reviews
			</ButtonV2>
		</div>
	);
};
