import { HeroIllustration } from "./HeroIllustration";
import { HeroAdressInput } from "./HeroAdressInput";
import { HeroValueProps } from "./HeroValueProps";

export const HeroSection = ({
	title,
	isTarget,
}: {
	title: string;
	isTarget?: boolean;
}) => {
	return (
		<div id="landing-hero-section">
			<HeroIllustration isTarget={isTarget} />
			<HeroAdressInput title={title} />
			<HeroValueProps />
		</div>
	);
};
