import React from "react";
import moment from "moment-timezone";

const SIGNUPS_PER_DAY = 100;
const AVG_ASSESSMENT_REDUCTION_PER_SIGNUP = 22349;

const fakeTotalAssessmentReductions = {
	date: "2024-07-30",
	dollars: 9_989_863_552,
};

const totalAssessmentReductionRateInSeconds = ({
	randomize = false,
}: {
	randomize?: boolean;
} = {}) => {
	const randomMultiplier = randomize ? 0.5 + Math.random() : 1;
	return Math.floor(
		(randomMultiplier *
			(SIGNUPS_PER_DAY * AVG_ASSESSMENT_REDUCTION_PER_SIGNUP)) /
			24 /
			60 /
			60
	);
};

const totalAssessmentReductionToDate = ({
	startDate,
	endDate,
	startDateDollars,
}: {
	startDate: moment.Moment;
	endDate: moment.Moment;
	startDateDollars: number;
}) => {
	const seconds = endDate.diff(startDate, "seconds");
	return startDateDollars + totalAssessmentReductionRateInSeconds() * seconds;
};

const calculateCurrentTotalAssessmentReductions = () =>
	totalAssessmentReductionToDate({
		startDate: moment(fakeTotalAssessmentReductions.date),
		endDate: moment(),
		startDateDollars: fakeTotalAssessmentReductions.dollars,
	});

const useTotalAssessmentReductions = () => {
	const [totalAssessmentReductions, setTotalAssessmentReductions] =
		React.useState<number | null>(null);

	React.useEffect(() => {
		setTotalAssessmentReductions(calculateCurrentTotalAssessmentReductions());
		const interval = setInterval(() => {
			if (
				moment().tz("America/Chicago").hour() >= 8 &&
				moment().tz("America/Chicago").hour() < 22
			) {
				setTotalAssessmentReductions(prev => {
					if (prev === null) {
						return calculateCurrentTotalAssessmentReductions();
					}

					return (
						prev + totalAssessmentReductionRateInSeconds({ randomize: true })
					);
				});
			}
		}, 3000);
		return () => clearInterval(interval);
	}, []);

	const totalAssessmentReductionsCharArray = React.useMemo(
		() =>
			totalAssessmentReductions
				? ["$", ...Array.from(totalAssessmentReductions.toLocaleString())]
				: [],
		[totalAssessmentReductions]
	);

	return {
		totalAssessmentReductions,
		totalAssessmentReductionsCharArray,
	};
};

const BigNumberCounter = () => {
	const { totalAssessmentReductionsCharArray } = useTotalAssessmentReductions();
	return (
		<div id="big-number-counter">
			{totalAssessmentReductionsCharArray.map((char, idx) => {
				if (char === "$") {
					return (
						<p
							key={[char, idx].toString()}
							className="big-number-counter-char dollar-sign">
							{char}
						</p>
					);
				} else if (char === ",") {
					return (
						<p
							key={[char, idx].toString()}
							className="big-number-counter-char comma">
							{char}
						</p>
					);
				} else {
					return (
						<p
							key={[char, idx].toString()}
							className="big-number-counter-char digit">
							{char}
						</p>
					);
				}
			})}
		</div>
	);
};

export const BigNumberCounterSection = () => {
	return (
		<div id="landing-big-number-counter-section">
			<BigNumberCounter />
			<p id="landing-big-number-counter-description">
				Total assessment reductions
			</p>
		</div>
	);
};
