import { pricingFaqQuestionsWithIcons } from "../Pricing/Pricing";
import Grid from "elements/Grid";
import Button from "elements/Button";
import { FaqCard2Cols } from "elements/FaqCard";
import constants from "utils/constants";
import { StatesWithStatePage } from "utils/types";
import ButtonV2 from "elements/ButtonV2";

import Coinage from "public/landing-faq-icons/coinage.svg";
import Calculator from "public/landing-faq-icons/calculator.svg";
import Piggy from "public/landing-faq-icons/piggy.svg";
import Lock from "public/landing-faq-icons/lock.svg";
import Bill from "public/landing-faq-icons/bill.svg";
import Tax from "public/landing-faq-icons/tax.svg";
import Refer from "public/landing-faq-icons/refer.svg";
import Split from "public/landing-faq-icons/split.svg";
import Waat from "public/landing-faq-icons/waat.svg";
import Evaluation from "public/landing-faq-icons/evaluation.svg";

export const GEORGIA_FAQ_CARDS = [
	{
		Icon: <Tax />,
		title: "How Properties are appraised and taxed in Georgia",
		body: "Each year, the County Board of Assessors (BOA) uses mass appraisal technology to determine the market value of all properties based on factors such as use type and neighborhood. Market value is defined as “the amount a knowledgeable buyer would pay for the property and a willing seller would accept for the property at an arm's length, bona fide sale.”",
	},
	{
		Icon: <Bill />,
		title: "How to appeal property taxes in Georgia",
		body: "If after receiving your assessment notice you think your property has been inaccurately assessed, you can file an appeal. You’ll have 45 days to appeal with the Board of Assessors after receiving your notice and can find instructions for appealing your property taxes on the county website. After submitting an appeal, the BOA may offer to adjust a property's taxable value based on the evidence submitted. If a reduction is offered, the property owner is not obligated to accept the offer. Typically, the offer is a fraction of the amount a property owner or agent could receive if they choose to push the appeal to the Board of Equalization (BOE). The BOE is considered a formal hearing in which the board of hearing officers will listen to the appeal and make a ruling. The expected appeal timeline of a BOE hearing is around 180 days. Note: In situations where an appeal was not resolved by the time a tax bill was mailed, you are required to pay a temporary bill. Legislation HB 197 states that if a property’s value is under appeal, the taxpayer has the option to pay 100% of the proposed bill, the lesser of 85% of the proposed bill, or taxes based on the prior year's value. Once the appeal is resolved, the tax bill will be recalculated to factor in the payment already submitted.",
	},
	{
		Icon: <Calculator />,
		title: "How do you lower my taxes?",
		body: "We manage the end-to-end process of property tax reduction, from paperwork filing to discussions with county assessors and attending appeal board hearings. We use a combination of proprietary technology and local knowledge to build the best evidence to reduce property taxes.",
	},
	{
		Icon: <Split />,
		title: "What is the 85/15 split?",
		body: "When an appeal is pending, you will be issued a temporary tax bill at the lesser of 85% of the proposed market value or prior year's market value. If you are able to get a reduction below the value paid on the temp tax bill, the owner would be entitled to a refund for the difference. Otherwise you'll just pay the difference after the appeal is complete.",
	},
	{
		Icon: <Piggy />,
		title: "How to save money on property taxes in Georgia",
		body: "Property tax exemptions in Georgia can reduce your home's assessed value and, therefore, your taxes. There is a statewide exemption for $2,000. However, this applies only to the statewide property tax. The major exemption that Georgia offers property owners is the homestead exemption which applies only to the homeowner’s primary residence. The homestead exemption allows you to take a predetermined dollar amount taken off your yearly taxable account. In some municipalities, your homestead exemption can also cap the percentage increase in your property's taxable value by 3% or to reflect the consumer price index (CPI) at the time of assessment. The county will use whichever value, 3% or CPI, is lower. While the homestead exemption is the main property tax exemption offered in Georgia, there are further exemptions that are offered to individuals with specific qualifications. The additional exemptions are offered to individuals who are senior citizens, have a disability, are veterans with a 100% VA rated disability, or are the child or unremarried spouse of a deceased veteran. This is not an exhaustive list and these exemptions do not have uniform requirements set by the state, so please check with your local county assessor's office to see if you qualify for these further savings. Beyond taking every exemption you qualify for, you can also appeal your property’s assessed value with the county.",
	},
	{
		Icon: <Lock />,
		title: "How are tax exemptions taken into account?",
		body: "Our contingency fee is applied to actual property tax savings in the year we appeal. If you have an active homestead exemption, over 65 exemption, or any other tax exemption applied to your property, we will adjust your final fee by the appropriate discounted amount. You won't be charged unless your final property tax bill is actually reduced, and we have a signed document from your taxing authority to prove it.",
	},
	{
		Icon: <Bill />,
		title: "How are property taxes calculated?",
		body: 'Each year, the county tax assessor generates an estimated market value and an "assessed value" for each property. These values form the base value (“basis”) upon which the property is taxed. The basis is multiplied by the tax rate for your specific tax jurisdictions to calculate the property tax bill.',
	},
	{
		Icon: <Waat />,
		title: "Is there any risk to my property by signing up?",
		body: "There is no risk to signing up a property to protest or appeal in Texas, California, New York and Florida. In Washington and Georgia it is possible that a property's taxable value can increase, however we review all relevant market data before filing. If we think there is any risk then we will inform you that we are not filing. It's still important to review your property taxes each year to ensure you're only ever paying what's fair.",
	},
];

const NEWYORK_FAQ_CARDS = [
	{
		Icon: <Tax />,
		title: "How Properties are appraised and taxed in New York",
		body: "Each assessing unit (county, township, village, etc.) has an assessor who determines market value. The market value is then multiplied by the municipalities uniform ratio (level of assessment) to determine the assessed or taxable value. Some municipalities assess at 100% of market value but most utilize a lower ratio. Tax rates are determined by local jurisdictions and the tax rate is multiplied by the assessed value to calculate property tax.",
	},
	{
		Icon: <Bill />,
		title: "How to file a property tax grievance in New York?",
		body: "If you feel your property is over assessed you can file a grievance with either the Board of Assessment Review (BAR) or the Assessment Review Commission (ARC) in Nassau County. If you are not satisfied with the decision you have the option to file for Small Claims Assessment Review (SCAR) where you and the assessor will present your case before a hearing officer. You must file at the BAR / ARC level in order to be eligible for SCAR.",
	},
	{
		Icon: <Waat />,
		title: "How do you lower my taxes?",
		body: "We manage the end-to-end process of property tax reduction, from paperwork filing to discussions with county assessors and attending appeal board hearings. We use a combination of proprietary technology and local knowledge to build the best evidence to reduce property taxes.",
	},
	{
		Icon: <Coinage />,
		title: "How is your fee calculated?",
		body: "Our fee is applied to actual property tax savings in the year we appeal. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250. You can find pricing for your region here.",
	},
	{
		Icon: <Piggy />,
		title: "How to save money on property taxes in New York",
		body: "There are several tax exemptions available for homeowners to save money on taxes. The largest is STAR which is a school tax relief program. Additionally there are exemptions available for seniors, veterans, and people with disabilities who qualify. Some capital improvements may also qualify for an exemption.",
	},
	{
		Icon: <Lock />,
		title:
			"How are tax exemptions and STAR credits taken into account in New York?",
		body: "Tax exemptions are deducted from the total taxes to lower your tax bill. Homeowners currently receiving the STAR exemption have the option to continue receiving the exemption or convert to the STAR credit in which you will receive a check or direct deposit. New qualifying homeowners may sign up for the STAR credit as the STAR exemption is no longer available.",
	},
	{
		Icon: <Calculator />,
		title: "How are property taxes calculated?",
		body: "Each year, the county tax assessor generates an estimated market value and an 'assessed value' for each property. These values form the base value (“basis”) upon which the property is taxed. The basis is multiplied by the tax rate for your specific tax jurisdictions to calculate the property tax bill.",
	},
	{
		Icon: <Evaluation />,
		title: "Is there any risk to my property by signing up?",
		body: "There is no risk to signing up a property to protest or appeal in Texas, California, New York and Florida. In Washington and Georgia it is possible that a property's taxable value can increase, however we review all relevant market data before filing. If we think there is any risk then we will inform you that we are not filing. It's still important to review your property taxes each year to ensure your only ever paying what's fair.",
	},
];

export const FAQSection = ({ target }: { target?: StatesWithStatePage }) => {
	const getFaqCards = () => {
		if (target === "GA") {
			return GEORGIA_FAQ_CARDS;
		} else if (target === "NY") {
			return NEWYORK_FAQ_CARDS;
		} else {
			return pricingFaqQuestionsWithIcons;
		}
	};
	return (
		<div id="landing-faq-section">
			<p className="landing-section-title mb-4">Frequently Asked Questions</p>
			<Grid id="pricing-faq-grid" className="mb-4">
				<FaqCard2Cols faqs={getFaqCards()} />
			</Grid>
			<ButtonV2
				className="mx-auto landing-signup-today mt-4"
				size="large"
				toAppealFlow={{ referrer: "FAQ section Sign Up Today" }}>
				Sign Up Today
			</ButtonV2>
		</div>
	);
};
